body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.table td {
  vertical-align: middle;
}

.table td,
.table th {
  border: 1px solid #dee2e6 !important;
}

.tableInput {
  padding: 0 10px !important;
}

@media print {
  body {
    transform: scale(0.9);
  }
  .no-print {
    display: none !important;
  }
}

input:invalid {
  background-color: rgba(255, 0, 0, 0.4);
}
